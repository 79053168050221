//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { useIntl, FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';

import * as UI from '../../../../ui';
import Config from '../../../../../config';

import { sections } from '../../../constants';

const { DynamicContainer, ContainerType, LocalizedLink } = UI;

const Hero = ({ onLoad, rules, styles }) => {
    const [textsLoadedCount, setTextsLoadedCount] = useState(0);
    const intl = useIntl();
    const images = useStaticQuery(graphql`
        query HeaderImages {
            meaningful: file(relativePath: { eq: "christmas2020/meaningful.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            christmas: file(relativePath: { eq: "christmas2020/christmas.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    const handleTextLoaded = () => {
        setTextsLoadedCount(count => count + 1);
    };

    useEffect(() => {
        if (textsLoadedCount === 2) {
            onLoad();
        }
    }, [textsLoadedCount, onLoad]);

    return (
        <>
            <Img
                fluid={images.meaningful.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'christmasPromotion.header.meaningful' })}
                className={styles.paintedText}
                imgStyle={{ objectFit: 'contain' }}
                onLoad={handleTextLoaded}
            />
            <DynamicContainer type={ContainerType.FLEX} extend={rules.extendSecondRowContainer}>
                <Img
                    fluid={images.christmas.childImageSharp.fluid}
                    alt={intl.formatMessage({ id: 'christmasPromotion.header.christmas' })}
                    className={styles.paintedText2}
                    onLoad={handleTextLoaded}
                />
                <span className={styles.promotionSentence}>
                    <FormattedMessage id="christmasPromotion.header.promotion.part1" />{' '}
                    <LocalizedLink
                        anchor={`#${sections.PROMOTIONS_DESCRIPTION}`}
                        className={styles.promotionLink}
                        to={Config.routes['/christmas2020']}
                    >
                        <FormattedMessage id="christmasPromotion.header.promotion.part2" />
                    </LocalizedLink>
                </span>
            </DynamicContainer>
        </>
    );
};

Hero.propTypes = {
    onLoad: PropTypes.func.isRequired,
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default Hero;
