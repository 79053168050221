import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';
import Config from '../../../../config';

const { LocalizedLink } = UI;

const JoyButton = ({ anchor, link, styles, rules }) => {
    const label = <FormattedMessage id="christmasPromotion.joyButton.label" />;

    if (anchor) {
        return (
            <LocalizedLink anchor={anchor} to={Config.routes['/christmas2020']} extend={{ link: rules.button }}>
                {label}
            </LocalizedLink>
        );
    }

    if (link) {
        return (
            <a href={link} target="_blank" rel="noopener noreferrer" className={styles.button}>
                {label}
            </a>
        );
    }

    console.warn('No link neither an anchor provided into JoyButton, creating blind button');

    return <span className={styles.button}>{label}</span>;
};

JoyButton.propTypes = {
    anchor: PropTypes.string,
    link: PropTypes.string,
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

JoyButton.defaultProps = {
    anchor: null,
    link: null,
};

export default JoyButton;
