import bg from '../../../../../static/images/loader/background.png';

export const extendContainer = () => ({
    container: ({ theme: { colors } }) => ({
        backgroundColor: colors.darkBlue,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'contain',
        position: 'relative',
    }),
});

export const extendLogo = () => ({
    logo: () => ({
        tabletLarge: {
            width: 234,
            height: 73,
        },
    }),
});

export const extendMenuContainer = () => ({
    container: () => ({
        justifyContent: 'space-between',
    }),
});

export const extendHeroContainer = () => ({
    container: ({ theme }) => ({
        paddingTop: '2.5rem',
        paddingBottom: '2.5rem',

        color: theme.colors.white,

        tabletLarge: {
            paddingBottom: '4.5rem',
        },
    }),
});
