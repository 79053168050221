import React from 'react';
import { PropTypes } from 'prop-types';

const SectionText = ({ children, styles }) => <p className={styles.text}>{children}</p>;

SectionText.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    styles: PropTypes.shape().isRequired,
};

export default SectionText;
