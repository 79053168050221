export const extendContainer = () => ({
    container: () => ({
        paddingTop: '2rem',
        paddingBottom: '3.435rem',

        tabletLarge: {
            paddingTop: '10.875rem',
            paddingBottom: '5.875rem',
        },
    }),
});

export const extendTextContainer = () => ({
    container: () => ({
        tabletLarge: {
            flexGrow: '0',
            flexShrink: '0',
            flexBasis: '40%',

            paddingTop: '3.375rem',
        },
    }),
});
export const extendText = () => ({
    text: () => ({
        marginTop: '2.5rem',
    }),
});
