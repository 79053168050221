import React from 'react';

import { ChristmasPromotionPage } from '../modules/christmas-promotion';
import Seo from '../components/SEO';
import * as Constants from '../constants';

const ChristmasPage = () => {
    return (
        <>
            <Seo pageId={Constants.pages.CHRISTMAS_PROMOTION} image="/images/christmas_social_preview.png" />
            <ChristmasPromotionPage />
        </>
    );
};

export default ChristmasPage;
