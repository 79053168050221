const width = 517;
const height = 620;

export const container = () => ({
    width,
    height,

    position: 'relative',

    borderRadius: 8,
});

export const image = () => ({
    width,
    height,

    transitionProperty: 'opacity',
    transitionDuration: '.7s',
    transitionTimingFunction: 'ease-in-out',

    boxShadow: '-28px 28px #4e81ff',
    borderRadius: 8,
});
