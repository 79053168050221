export const extendContainer = () => ({
    container: ({ theme }) => ({
        paddingTop: '0',
        paddingBottom: '0',

        backgroundImage: `linear-gradient(90deg, ${theme.colors.blue} 0%, #5185ff 100%)`,
    }),
});

export const extendInnerContainer = () => ({
    container: () => ({
        paddingTop: '2.5rem',
        paddingBottom: '2.5rem',

        tabletLarge: {
            paddingTop: '5.9375rem',
            paddingBottom: '5.9375rem',
        },
    }),
});

export const extendImageContainer = () => ({
    container: () => ({
        flexGrow: '1',
        flexShrink: '0',
        flexBasis: 'auto',

        // to preserve promo image shadow from being cut off
        padding: 28,

        tabletMax: {
            display: 'none',
        },
    }),
});

export const intro = () => ({
    paddingLeft: 0,
    marginBottom: '2rem',

    tabletLarge: {
        paddingLeft: '2rem',
        marginBottom: '3.25rem',
    },
});

export const extendDecsriptionContainer = () => ({
    container: ({ theme }) => ({
        flexGrow: '0',
        flexShrink: '1',
        flexBasis: 'auto',

        color: theme.colors.white,

        tabletLarge: {
            paddingLeft: '3.625rem',
        },
    }),
});

export const title = ({ theme }) => ({
    color: theme.colors.white,
});

export const organization = ({ theme }) => ({
    paddingTop: '1.15rem',
    paddingRight: '0',
    paddingBottom: '1.15rem',
    paddingLeft: '0',

    transitionProperty: 'background',
    transitionDuration: '.7s',
    transitionTimingFunction: 'ease',

    backgroundColor: 'transparent',

    ':hover': {
        // TODO: gradient
        // backgroundImage: `linear-gradient(90deg, ${theme.colors.blue} 0%, ${} 100%)`,
        backgroundColor: theme.colors.blue,
    },

    tabletLarge: {
        paddingTop: '2.25rem',
        paddingRight: '2rem',
        paddingBottom: '2.25rem',
        paddingLeft: '2rem',
    },
});
