import React from 'react';
import { PropTypes } from 'prop-types';
import giftImage from '../../../../../../static/images/christmas2020/3d_gift.png';
import treeImage from '../../../../../../static/images/christmas2020/3d_tree.png';
import santaImage from '../../../../../../static/images/christmas2020/3d_santa.png';

const AboutGraphic = ({ styles }) => (
    <div className={styles.container}>
        <img src={giftImage} alt="" className={styles.gift} />
        <img src={treeImage} alt="" className={styles.tree} />
        <br />
        <img src={santaImage} alt="" className={styles.santa} />
    </div>
);

AboutGraphic.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default AboutGraphic;
