import { combineRules } from 'fela';

export const paintedText = ({ show }) => ({
    flexGrow: '1',
    flexShrink: '0',
    flexBasis: 'auto',

    minWidth: '70%',

    transform: `translateX(${show ? 0 : -100}px)`,
    opacity: show ? 1 : 0,
    transitionProperty: 'transform, opacity',
    transitionDuration: '1.5s',
    transitionTimingFunction: 'ease-in-out',
});

export const paintedText2 = combineRules(paintedText, ({ show }) => ({
    transform: `translateX(${show ? 0 : 100}px)`,
}));

export const extendSecondRowContainer = () => ({
    container: () => ({
        position: 'relative',
        top: -28,

        flexDirection: 'column',

        alignItems: 'flex-end',

        tablet: {
            flexDirection: 'row-reverse',
            top: -68,
        },

        tabletLarge: {
            top: -78,
        },

        desktop1200: {
            top: -98,
        },
    }),
});

export const promotionSentence = ({ theme }) => ({
    flexGrow: '0',
    flexShrink: '1',
    flexBasis: 'auto',
    alignSelf: 'flex-start',

    marginTop: '1.5rem',

    color: theme.colors.white,

    fontSize: '1.75rem',
    fontWeight: 'bold',

    tablet: {
        alignSelf: 'flex-end',
    },
});

export const promotionLink = ({ theme }) => ({
    color: theme.colors.cyan,
    textDecoration: 'underline',
    textDecorationColor: theme.colors.blue,
});
