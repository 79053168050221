import bg from '../../../../../static/images/loader/background.png';

export const extendContainer = () => ({
    container: ({ theme: { colors } }) => ({
        backgroundColor: colors.darkBlue,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'contain',
        position: 'relative',

        tabletLarge: {
            paddingTop: 0,
        },
    }),
});

export const extendContainerInner = () => ({
    container: () => ({
        paddingTop: '1.5rem',

        tabletLarge: {
            paddingTop: '3.5rem',
            paddingBottom: '3.875rem',
        },
    }),
});

export const extendDivider = () => ({
    container: () => ({
        display: 'none',

        tablet: {
            display: 'block',

            gridColumnStart: 1,
            gridColumnEnd: 'span 12',
            borderWidth: '0.13rem',
        },
    }),
});
