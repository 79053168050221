//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as UI from '../../../ui';

import { organizations, sections } from '../../constants';

import SectionTitle from '../SectionTitle';
import SectionText from '../SectionText';
import OrganizationCard from './OrganizationCard';

const { DynamicContainer, ContainerType } = UI;

const ScoreBoards = ({ rules }) => {
    const images = useStaticQuery(graphql`
        query Images {
            asistence: file(relativePath: { eq: "christmas2020/asistence_promo.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            eduin: file(relativePath: { eq: "christmas2020/eduin_promo.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            lekari: file(relativePath: { eq: "christmas2020/lekari_promo.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <DynamicContainer id={sections.SCOREBOARD} as={ContainerType.SECTION} extend={rules.extendContainer} isFluid>
            <SectionTitle invertColor>
                <FormattedMessage id="christmasPromotion.scoreboard.title" />
            </SectionTitle>
            <SectionText invertColor>
                <FormattedMessage id="christmasPromotion.scoreboard.text" />
            </SectionText>

            <DynamicContainer extend={rules.extendScoreboardContainer} type={ContainerType.GRID}>
                {organizations.map(organization => (
                    <DynamicContainer key={organization.id} columns={4}>
                        <OrganizationCard {...organization} image={images[organization.id]} />
                    </DynamicContainer>
                ))}
            </DynamicContainer>
        </DynamicContainer>
    );
};

ScoreBoards.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default ScoreBoards;
