import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';

import { sections } from '../../constants';
import SectionTitle from '../SectionTitle';
import SectionText from '../SectionText';
import AboutGraphic from './AboutGraphic';

const { DynamicContainer, ContainerType } = UI;

const AboutSection = ({ rules }) => (
    <DynamicContainer
        extend={rules.extendContainer}
        type={ContainerType.FLEX}
        as={ContainerType.SECTION}
        id={sections.ABOUT}
        isFluid
    >
        <DynamicContainer extend={rules.extendTextContainer}>
            <SectionTitle>
                <FormattedMessage id="christmasPromotion.about.title" />
            </SectionTitle>

            <SectionText extend={rules.extendText}>
                <FormattedMessage id="christmasPromotion.about.text1" />
            </SectionText>

            <SectionText extend={rules.extendText}>
                <FormattedMessage id="christmasPromotion.about.text2" />
            </SectionText>
        </DynamicContainer>

        <AboutGraphic />
    </DynamicContainer>
);

AboutSection.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default AboutSection;
