import React, { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import asistenceLogo from '../../../../static/images/christmas2020/asistence_logo.svg';
import eduinLogo from '../../../../static/images/christmas2020/eduin_logo.svg';
import lekariLogo from '../../../../static/images/christmas2020/lekari_logo.svg';

interface Organization {
    id: string;
    logo: string;
    name: ReactNode;
    description: ReactNode;
    promotionId: number;
    promotionUrl: string;
    amount: number;
    goal: number;
}

export const organizations = [
    {
        id: 'asistence',
        logo: asistenceLogo,
        name: <FormattedMessage id="christmasPromotion.organization.asistence.name" />,
        description: <FormattedMessage id="christmasPromotion.organization.asistence.description" />,
        promotionId: 1201777,
        promotionUrl: 'https://www.darujme.cz/vyzva/1201777',
        amount: 48000,
        goal: 75000,
    },
    {
        id: 'eduin',
        logo: eduinLogo,
        name: <FormattedMessage id="christmasPromotion.organization.eduin.name" />,
        description: <FormattedMessage id="christmasPromotion.organization.eduin.description" />,
        promotionId: 1201792,
        promotionUrl: 'https://www.darujme.cz/vyzva/1201792',
        amount: 53800,
        goal: 75000,
    },
    {
        id: 'lekari',
        logo: lekariLogo,
        name: <FormattedMessage id="christmasPromotion.organization.doctors.name" />,
        description: <FormattedMessage id="christmasPromotion.organization.doctors.description" />,
        promotionId: 1201793,
        promotionUrl: 'https://www.darujme.cz/vyzva/1201793',
        amount: 57200,
        goal: 75000,
    },
] as const satisfies ReadonlyArray<Organization>;
