export const container = () => ({
    display: 'flex',
    alignItems: 'center',

    borderRadius: 4,
});

export const logo = ({ theme }) => ({
    width: 60,
    height: 60,

    flexGrow: '0',
    flexShrink: '0',
    flexBasis: '60px',

    marginRight: '1.25rem',

    paddingTop: '1rem',
    paddingRight: '0.25rem',
    paddingBottom: '1rem',
    paddingLeft: '0.25rem',

    boxSizing: 'border-box',

    borderRadius: 4,

    backgroundColor: theme.colors.white,
});

export const title = ({ theme }) => ({
    marginTop: 0,
    marginBottom: '0.25rem',

    color: theme.colors.white,

    fontSize: '1.75rem',
    fontWeight: 'bold',
});

export const description = () => ({
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,

    color: 'rgb(255, 255, 255)',

    opacity: 0.7,

    fontWeight: 500,
    lineHeight: '1.5rem',
});
