import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';

import { organizations, sections } from '../../constants';
import SectionTitle from '../SectionTitle';
import SectionText from '../SectionText';
import OrganizationInfo from '../OrganizationInfo';
import PromoImage from './PromoImage';

const { DynamicContainer, ContainerType } = UI;

const PromotionsDescriptionSection = ({ styles, rules }) => {
    const [activeOrganization, setActiveOrganization] = React.useState(null);
    return (
        <DynamicContainer extend={rules.extendContainer}>
            <DynamicContainer
                type={ContainerType.FLEX}
                as={ContainerType.SECTION}
                id={sections.PROMOTIONS_DESCRIPTION}
                extend={rules.extendInnerContainer}
                isFluid
            >
                <DynamicContainer extend={rules.extendImageContainer}>
                    <PromoImage activeOrganization={activeOrganization || organizations[0].id} />
                </DynamicContainer>
                <DynamicContainer extend={rules.extendDecsriptionContainer}>
                    <div className={styles.intro}>
                        <SectionTitle invertColor>
                            <FormattedMessage id="christmasPromotion.organizations.title" />
                        </SectionTitle>
                        <SectionText invertColor>
                            <FormattedMessage id="christmasPromotion.organizations.text" />
                        </SectionText>
                    </div>

                    {organizations.map(({ id, name, logo, description, promotionUrl }) => (
                        <OrganizationInfo
                            key={id}
                            id={id}
                            name={name}
                            logo={logo}
                            description={description}
                            extend={{ container: rules.organization }}
                            renderLogo={logoImg => (
                                <a href={promotionUrl} target="_blank" rel="noopener noreferrer">
                                    {logoImg}
                                </a>
                            )}
                            onMouseOver={() => setActiveOrganization(id)}
                            onFocus={() => setActiveOrganization(id)}
                            onMouseOut={() => setActiveOrganization(null)}
                            onBlur={() => setActiveOrganization(null)}
                        />
                    ))}
                </DynamicContainer>
            </DynamicContainer>
        </DynamicContainer>
    );
};

PromotionsDescriptionSection.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default PromotionsDescriptionSection;
