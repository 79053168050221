import React from 'react';

import Header from '../Header';
import AboutSection from '../AboutSection';
import PromotionsDescriptionSection from '../PromotionsDescriptionSection';
import PromotionSection from '../PromotionSection';
import ScoreBoardsSection from '../ScoreBoardsSection';
import Footer from '../Footer';

const ChristmasPromotionPage = ({ styles }) => (
    <>
        <Header />
        <AboutSection />
        <PromotionsDescriptionSection />
        <PromotionSection />
        <Footer>
            <ScoreBoardsSection />
        </Footer>
    </>
);

export default ChristmasPromotionPage;
