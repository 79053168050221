import { combineRules } from 'fela';

const base = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',

    color: theme.colors.white,
});

export const labels = combineRules(base, () => ({
    marginBottom: '0.25rem',

    fontSize: '0.875rem',

    opacity: 0.5,
}));

export const amounts = combineRules(base, () => ({
    marginBottom: '0.75rem',
    fontSize: '1.125rem',
}));

export const amount = () => ({
    opacity: 1,
});

export const goal = () => ({
    opacity: 0.5,
});

export const progressBar = () => ({
    paddingTop: '0.375rem',
    paddingRight: '0.375rem',
    paddingBottom: '0.375rem',
    paddingLeft: '0.375rem',
    backgroundColor: '#0d0d34',
});

export const progress = ({ theme }) => ({
    height: '1.375rem',

    backgroundImage: `linear-gradient(90deg, #ff5bff 0%, ${theme.colors.fuchsia} 100%)`,

    transitionProperty: 'width',
    transitionDuration: '1s',
    transitionTimingFunction: 'ease',
});
