import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { hooks } from '../../../../utils';

import Menu from './Menu';
import Hero from './Hero';

const { DynamicContainer, ContainerType, Logo, ColorLine, colorLineKeyframes } = UI;

const { useStars } = hooks;

const Header = ({ rules }) => {
    const [heroLoaded, setHeroLoaded] = React.useState(false);
    const { colorLines } = useStars();

    return (
        <DynamicContainer extend={rules.extendContainer}>
            <DynamicContainer type={ContainerType.FLEX} extend={rules.extendMenuContainer}>
                <Logo link extend={rules.extendLogo} />
                <Menu />
            </DynamicContainer>
            <DynamicContainer extend={rules.extendHeroContainer} isFluid>
                <Hero show={heroLoaded} onLoad={() => setHeroLoaded(true)} />
            </DynamicContainer>

            <ColorLine keyframe={colorLineKeyframes.leftTranslate} {...colorLines[1]} />
            <ColorLine keyframe={colorLineKeyframes.rightTranslate} {...colorLines[2]} />
            <ColorLine keyframe={colorLineKeyframes.rightTranslate} {...colorLines[3]} />
        </DynamicContainer>
    );
};

Header.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default Header;
