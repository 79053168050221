import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as UI from '../../../../ui';
import Config from '../../../../../config';

import JoyButton from '../../JoyButton';
import { sections } from '../../../constants';

const { LocalizedLink } = UI;

const menuItems = [
    { intlId: 'christmasPromotion.menu.about', anchor: `#${sections.ABOUT}` },
    { intlId: 'christmasPromotion.menu.promotion', anchor: `#${sections.PROMOTION}` },
    { intlId: 'christmasPromotion.menu.scoreboard', anchor: `#${sections.SCOREBOARD}` },
];

const Menu = ({ styles, rules }) => (
    <ul className={styles.menu}>
        {menuItems.map(({ anchor, intlId }) => (
            <li key={anchor} className={styles.item}>
                <LocalizedLink anchor={anchor} to={Config.routes['/christmas2020']} extend={{ link: rules.itemLink }}>
                    <FormattedMessage id={intlId} />
                </LocalizedLink>
            </li>
        ))}
        <li className={styles.buttonItem}>
            <JoyButton anchor={`#${sections.PROMOTION}`} extend={{ button: rules.joyButton }} />
        </li>
    </ul>
);

Menu.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default Menu;
