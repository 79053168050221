import { combineRules } from 'fela';

export const container = () => ({
    display: 'none',

    tabletLarge: {
        display: 'block',

        flexShrink: '0',
        flexGrow: '1',

        overflow: 'visible',

        textAlign: 'right',
    },
});

export const base = () => ({});

export const gift = combineRules(base, () => ({
    transform: 'translate(-40%, 50%)',
}));
export const tree = combineRules(base, () => ({
    transform: 'translate(-50%, -20%)',
}));
export const santa = combineRules(base, () => ({}));
