export const title = ({ theme }) => ({
    color: theme.colors.white,
});

export const extendContainer = ({ theme }) => ({
    container: () => ({
        paddingTop: '2.5rem',
        paddingBottom: '1.5rem',

        textAlign: 'left',

        color: theme.colors.white,

        tabletLarge: {
            paddingTop: '4.5rem',
            paddingBottom: 0,
        },
    }),
});

export const extendScoreboardContainer = () => ({
    container: () => ({
        tabletLarge: {
            paddingTop: '2.75rem',
            paddingBottom: '3.75rem',
        },
    }),
});
