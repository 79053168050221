import { combineRules } from 'fela';
import oneImage from '../../../../../static/images/christmas2020/1.svg';
import twoImage from '../../../../../static/images/christmas2020/2.svg';
import threeImage from '../../../../../static/images/christmas2020/3.svg';

export const extendContainer = () => ({
    container: () => ({
        paddingTop: '2rem',
        paddingBottom: '4.35rem',

        textAlign: 'center',

        tabletLarge: {
            paddingTop: '4.8655rem',
            paddingBottom: '7.75rem',
        },
    }),
});

export const extendStepsContainer = () => ({
    container: () => ({
        tabletLarge: {
            paddingTop: '6.5rem',
            paddingRight: '6rem',
            paddingBottom: '2rem',
            paddingLeft: '6rem',
        },
    }),
});

export const base = () => ({
    paddingTop: '0',
    paddingRight: '3rem',
    paddingBottom: '0',
    paddingLeft: '3rem',

    display: 'grid',
    gridTemplateRows: '3fr 1fr',
    gridRowGap: '1.375rem',

    textAlign: 'left',
    lineHeight: '1.3125rem',

    backgroundRepeat: 'no-repeat',
});

export const clickContainer = combineRules(base, () => ({
    backgroundImage: `url(${oneImage})`,
}));

export const moneyContainer = combineRules(base, () => ({
    backgroundImage: `url(${twoImage})`,
}));

export const smileContainer = combineRules(base, () => ({
    backgroundImage: `url(${threeImage})`,
}));

export const image = () => ({
    alignSelf: 'center',
    justifySelf: 'center',
});
