//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import { PropTypes } from 'prop-types';
import Img from 'gatsby-image';
import { useIntl } from 'react-intl';

import { organizations } from '../../../constants';

const PromoImage = ({ activeOrganization, styles }) => {
    const intl = useIntl();
    const images = useStaticQuery(graphql`
        query PromoImages {
            asistence: file(relativePath: { eq: "christmas2020/asistence_promo.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            eduin: file(relativePath: { eq: "christmas2020/eduin_promo.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            lekari: file(relativePath: { eq: "christmas2020/lekari_promo.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <div className={styles.container}>
            {organizations.map(({ id }) => (
                <Img
                    key={id}
                    alt={intl.formatMessage({ id: 'christmasPromotion.organization.promoPhoto' })}
                    className={styles.image}
                    fluid={images[id].childImageSharp.fluid}
                    style={{ position: 'absolute', opacity: activeOrganization === id ? 1 : 0 }}
                />
            ))}
        </div>
    );
};

PromoImage.propTypes = {
    activeOrganization: PropTypes.string.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default PromoImage;
