import React from 'react';
import { PropTypes } from 'prop-types';

const SectionTitle = ({ children, styles }) => <h2 className={styles.title}>{children}</h2>;

SectionTitle.propTypes = {
    children: PropTypes.node.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default SectionTitle;
