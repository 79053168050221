import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';
import clickImage from '../../../../../static/images/christmas2020/3d_click.png';
import moneyImage from '../../../../../static/images/christmas2020/3d_money.png';
import smileImage from '../../../../../static/images/christmas2020/3d_smile.png';

import { sections, organizations } from '../../constants';
import SectionTitle from '../SectionTitle';
import SectionText from '../SectionText';

const { DynamicContainer, ContainerType } = UI;

const asistenceUrl = organizations.find(({ id }) => id === 'asistence')?.promotionUrl;
const eduinUrl = organizations.find(({ id }) => id === 'eduin')?.promotionUrl;
const lekariUrl = organizations.find(({ id }) => id === 'lekari')?.promotionUrl;

const PromotionSection = ({ styles, rules }) => (
    <DynamicContainer id={sections.PROMOTION} as={ContainerType.SECTION} extend={rules.extendContainer} isFluid>
        <SectionTitle>
            <FormattedMessage id="christmasPromotion.promotion.title" />
        </SectionTitle>
        <SectionText>
            <FormattedMessage id="christmasPromotion.promotion.text" />
        </SectionText>

        <DynamicContainer extend={rules.extendStepsContainer} type={ContainerType.GRID}>
            <DynamicContainer extend={{ container: rules.clickContainer }} columns={4}>
                <img src={clickImage} alt="" className={styles.image} />
                <span>
                    <FormattedMessage
                        id="christmasPromotion.promotion.click"
                        values={{
                            a1: chunk => (
                                <a href={asistenceUrl} target="_blank" rel="noopener noreferrer">
                                    {chunk}
                                </a>
                            ),
                            a2: chunk => (
                                <a href={eduinUrl} target="_blank" rel="noopener noreferrer">
                                    {chunk}
                                </a>
                            ),
                            a3: chunk => (
                                <a href={lekariUrl} target="_blank" rel="noopener noreferrer">
                                    {chunk}
                                </a>
                            ),
                        }}
                    />
                </span>
            </DynamicContainer>
            <DynamicContainer extend={{ container: rules.moneyContainer }} columns={4}>
                <img src={moneyImage} alt="" className={styles.image} />
                <FormattedMessage id="christmasPromotion.promotion.money" />
            </DynamicContainer>
            <DynamicContainer extend={{ container: rules.smileContainer }} columns={4}>
                <img src={smileImage} alt="" className={styles.image} />
                <FormattedMessage id="christmasPromotion.promotion.smile" />
            </DynamicContainer>
        </DynamicContainer>
    </DynamicContainer>
);

PromotionSection.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default PromotionSection;
