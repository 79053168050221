import React from 'react';
import { PropTypes } from 'prop-types';
import identity from 'lodash/identity';

const OrganizationInfo = ({
    logo,
    name,
    description,
    RootElement,
    styles,
    renderLogo,
    onMouseOver,
    onFocus,
    onMouseOut,
    onBlur,
}) => (
    <RootElement
        className={styles.container}
        onMouseOver={onMouseOver}
        onFocus={onFocus}
        onMouseOut={onMouseOut}
        onBlur={onBlur}
    >
        {renderLogo(<img src={logo} className={styles.logo} alt="" />)}
        <div className={styles.body}>
            <h3 className={styles.title}>{name}</h3>
            <p className={styles.description}>{description}</p>
        </div>
    </RootElement>
);

OrganizationInfo.propTypes = {
    logo: PropTypes.string.isRequired,
    name: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    styles: PropTypes.shape().isRequired,
    RootElement: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    renderLogo: PropTypes.func,
    onMouseOver: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseOut: PropTypes.func,
    onBlur: PropTypes.func,
};

OrganizationInfo.defaultProps = {
    RootElement: 'section',
    renderLogo: identity,
};

export default OrganizationInfo;
