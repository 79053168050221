import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

/* Reason: Usage of style property on FormattedNumber  */
/* eslint-disable react/style-prop-object */
const Score = ({ amount, goal, styles }) => {
    const isAmountDefined = amount !== null;
    const isGoalDefined = goal !== null;
    const progressPercentage = isAmountDefined && isGoalDefined ? Math.min(Math.round((amount / goal) * 100), 100) : 0;

    return (
        <>
            <div className={styles.labels}>
                <span>
                    <FormattedMessage id="christmasPromotion.score.amount" />
                </span>
                <span>
                    <FormattedMessage id="christmasPromotion.score.goal" />
                </span>
            </div>
            <div className={styles.amounts}>
                <span className={styles.amount}>
                    {isAmountDefined ? (
                        <FormattedNumber
                            compactDisplay="short"
                            value={amount}
                            style="currency"
                            currency="CZK"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                        />
                    ) : (
                        '?'
                    )}
                </span>
                <span className={styles.goal}>
                    {isAmountDefined ? (
                        <FormattedNumber
                            value={goal}
                            style="currency"
                            currency="CZK"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                        />
                    ) : (
                        '?'
                    )}
                </span>
            </div>
            <div className={styles.progressBar}>
                <div className={styles.progress} style={{ width: `${progressPercentage}%` }} />
            </div>
        </>
    );
};

Score.propTypes = {
    amount: PropTypes.number,
    goal: PropTypes.number,
    styles: PropTypes.shape().isRequired,
};

Score.defaultProps = {
    amount: null,
    goal: null,
};

export default Score;
