import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { hooks } from '../../../../utils';

const { DynamicContainer, ContainerType, SocialNetworks, LazyLoadContainer, Divider, ColorLine, colorLineKeyframes } =
    UI;

const { useStars } = hooks;

const Footer = ({ rules, lazyLoaded, reference, children }) => {
    const { colorLines } = useStars();

    return (
        <DynamicContainer ref={reference} extend={rules.extendContainer} columns={12}>
            {children}

            <ColorLine keyframe={colorLineKeyframes.leftTranslate} {...colorLines[1]} />
            <ColorLine keyframe={colorLineKeyframes.rightTranslate} {...colorLines[2]} />
            <ColorLine keyframe={colorLineKeyframes.rightTranslate} {...colorLines[3]} />

            <DynamicContainer
                extend={rules.extendContainerInner}
                type={ContainerType.GRID}
                columns={12}
                as={ContainerType.FOOTER}
                isFluid
            >
                <LazyLoadContainer unmountIfInvisible lazyLoaded={lazyLoaded}>
                    <Divider extend={rules.extendDivider} />
                    <SocialNetworks />
                </LazyLoadContainer>
            </DynamicContainer>
        </DynamicContainer>
    );
};

Footer.propTypes = {
    children: PropTypes.node,
    rules: PropTypes.shape().isRequired,
    lazyLoaded: PropTypes.bool,
    reference: PropTypes.shape(),
};

Footer.defaultProps = {
    children: null,
    lazyLoaded: false,
    reference: null,
};

export default Footer;
