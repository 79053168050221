import React from 'react';
import { PropTypes } from 'prop-types';
import Img from 'gatsby-image';

import OrganizationInfo from '../../OrganizationInfo';
import Score from '../Score';

const OrganizationCard = ({ id, promotionUrl, logo, name, description, image, amount, goal, styles, rules }) => (
    <section className={styles.container}>
        <a href={promotionUrl} target="_blank" rel="noopener noreferrer" className={styles.link}>
            <Img fluid={image.childImageSharp.fluid} className={styles.image} />
            <OrganizationInfo
                id={id}
                name={name}
                logo={logo}
                description={description}
                RootElement="header"
                extend={rules.extendOrganizationInfo}
            />
            <div className={styles.score}>
                <Score amount={amount} goal={goal} />
            </div>
        </a>
    </section>
);

OrganizationCard.propTypes = {
    id: PropTypes.string.isRequired,
    promotionUrl: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    name: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape().isRequired,
    }).isRequired,
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    amount: PropTypes.number.isRequired,
    goal: PropTypes.number.isRequired,
};

export default OrganizationCard;
