import { combineRules } from 'fela';

export const menu = () => ({
    display: 'inline-flex',
    alignItems: 'center',

    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingLeft: 0,

    listStyleType: 'none',
});

export const item = () => ({
    display: 'none',

    tabletLarge: {
        display: 'list-item',

        marginLeft: '1.875rem',
        marginRight: '1.875rem',
    },
});

export const buttonItem = combineRules(item, () => ({
    display: 'list-item',

    marginLeft: '0.25rem',
}));

export const joyButton = () => ({
    paddingTop: '0.65rem',
    paddingRight: '0.5rem',
    paddingBottom: '0.65rem',
    paddingLeft: '0.5rem',

    tabletLarge: {
        paddingTop: '1.25rem',
        paddingRight: '1rem',
        paddingBottom: '1.25rem',
        paddingLeft: '1rem',
    },
});

export const itemLink = ({ theme }) => ({
    color: theme.colors.white,
    textDecoration: 'none',

    fontSize: '1.125rem',
    fontWeight: 'bold',

    ':hover': {
        textDecoration: 'underline',
    },
});
