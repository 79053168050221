export const button = ({ theme }) => ({
    display: 'inline-block',
    paddingTop: '1.25rem',
    paddingRight: '1rem',
    paddingBottom: '1.25rem',
    paddingLeft: '1rem',

    borderRadius: 4,

    backgroundColor: theme.colors.blue,
    color: theme.colors.white,

    textAlign: 'center',

    textDecoration: 'none',
    fontSize: '1.125rem',
    fontWeight: 'bold',
});
