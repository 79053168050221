// Colors used only here hence I keep them local
const lightBlue = '#2b2b70';
const darkBlue = '#13133d';

export const container = () => ({
    height: '100%',

    marginTop: '1.75rem',

    borderRadius: 4,
    backgroundImage: `linear-gradient(180deg, ${lightBlue} 0%, ${darkBlue} 100%)`,
});

export const extendOrganizationInfo = () => ({
    container: () => ({
        paddingTop: '1.375rem',
        paddingRight: '1.25rem',
        paddingBottom: '1.375rem',
        paddingLeft: '1.25rem',
    }),
    logo: () => ({
        width: 66,
        height: 66,

        borderRadius: 0,

        backgroundColor: lightBlue,
    }),
    title: () => ({
        fontSize: '1.3125rem',
    }),
    description: () => ({
        fontSize: '0.875rem',
        lineHeight: '1.0625rem',
    }),
});

export const link = () => ({
    // clear link styles
    textDecoration: 'none',
    color: 'inherit',
});

export const score = () => ({
    paddingTop: '0.6875rem',
    paddingRight: '1.25rem',
    paddingBottom: '1.375rem',
    paddingLeft: '1.25rem',
});
